import { Autocomplete, Popper, PopperProps } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getUserInitials } from "@utils/user-utils";
import { ChipTag } from "@components/common/members-autocomplete/chip-tag";
import { UserListItem } from "@components/common/members-autocomplete/user-list-item";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { sphereColors } from "@styles/common-colors";
import { FaroTextFieldMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { LabelWithHelp } from "@components/common/label-with-help";
import {
  getOptionDisabled,
  getOptionDisabledMessage,
  getOptionLabel,
  getOptionOriginalOption,
  getOptionSubtitle,
  getOptionThumbnail,
  getOptionTitle,
  getSortedOptions,
} from "@components/common/members-autocomplete/members-autocomplete-utils";
import { useMembersAutocomplete } from "@hooks/autocomplete/use-members-autocomplete";
import { MembersAutocompleteProps } from "@components/common/members-autocomplete/members-autocomplete-types";

// TODO: Refactor to have common logics used in other components https://faro01.atlassian.net/browse/ST-1707
/**
 * This component shows an autocomplete selection that let's the user to
 * select multiple member from a list, and optionally type a valid email.
 * The component is generic enough that can be used for any purpose regarding
 * selecting multiple member. For selecting only one member see MemberAutocomplete.
 */
export function MembersAutocomplete({
  options,
  handleChange,
  onInputChange = () => undefined,
  placeHolder = "Email(s), comma separated",
  validateNewOption = () => true,
  isTeamName = () => true,
  message,
  initialValue = [],
  labelTitle,
  hasAutoFocus = false,
  isRequired = true,
  helpText = "To add multiple members/emails, simply separate them by pressing enter, space, semicolon, or comma.",
}: MembersAutocompleteProps): JSX.Element {
  const {
    selectedOptions,
    inputValue,
    onAutocompleteChange,
    onAutocompleteBlur,
    onAutocompleteInputChange,
    filterOptions,
  } = useMembersAutocomplete({
    initialValue,
    options,
    validateNewOption,
    isTeamName,
    handleChange,
    onInputChange,
  });

  return (
    <>
      {labelTitle && (
        <LabelWithHelp
          title={labelTitle}
          help={helpText}
          isRequired={isRequired}
        />
      )}
      <Autocomplete
        value={selectedOptions}
        multiple
        autoHighlight
        options={getSortedOptions(options)}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={getOptionLabel}
        onInputChange={(_, value) => onAutocompleteInputChange({ value })}
        onChange={(_, value, reason) =>
          onAutocompleteChange({
            value,
            reason,
          })
        }
        inputValue={inputValue}
        filterSelectedOptions
        freeSolo={true}
        autoSelect={false}
        clearOnBlur={false}
        popupIcon={<ArrowDropDownIcon />}
        onBlur={onAutocompleteBlur}
        renderInput={(params) => (
          <FaroSimpleTextField
            {...params}
            variant="outlined"
            placeholder={placeHolder}
            autoFocus={hasAutoFocus}
            sx={{
              "& .MuiOutlinedInput-root": { paddingY: "2px" },
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <ChipTag
              key={`chip-${index}`}
              tagProps={getTagProps}
              index={index}
              label={getOptionTitle(option)}
              isDisabled={getOptionDisabled(option)}
            />
          ));
        }}
        filterOptions={filterOptions}
        renderOption={(props, option, state) => (
          <li {...props} key={`listOption-${state.index}`}>
            <UserListItem
              item={{
                title: getOptionTitle(option),
                subtitle: getOptionSubtitle(option),
                userInitials: getUserInitials(getOptionOriginalOption(option)),
                userAvatarImg: getOptionThumbnail(option),
              }}
              inputValue={state.inputValue}
              disabledMessage={getOptionDisabledMessage(option)}
            />
          </li>
        )}
        PopperComponent={(props: PopperProps) => (
          <Popper
            {...props}
            sx={{
              // Hiding the no option dropdown when `freeSolo` is false, A
              // As there is no option to disable the no option dropdown
              "& .MuiAutocomplete-noOptions": {
                display: "none",
              },
            }}
            placement="bottom"
          />
        )}
        sx={{
          "& .MuiOutlinedInput-root": { paddingY: "2px" },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor:
                message?.type === "error"
                  ? sphereColors.red600
                  : sphereColors.blue600,
            },
        }}
      />
      <FaroTextFieldMessage message={message} />
    </>
  );
}
